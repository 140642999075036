<template>
    <div class="index">
        <!-- 心理测评 -->
        <div class="banner">
            <img v-if="!$store.state.isPhone" src="../../assets/images/banner/心理测评.jpg" alt="">
      <img v-else src="../../assets/images/banner/心理服务banner备份.jpg" alt=""/>
            <!-- <img src="../../assets/images/service/大厅3@2x.png" alt="">
             -->
            <!-- <img :src="$store.state.bannerUrl + $store.state.bannerImgs.xlcp" alt=""> -->
        </div>
        <div :class="isPc === 'true' ? 'main' : 'phoneStyle main'">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { touristLogin } from '@/api/gauge'
export default {
    data() {
        return {
            isPc: true,
        }
    },
    methods: {
         // 游客登录
    async tourist() {
      const res = await touristLogin();
      // console.log(res);
      if (res.code === 200) {
        sessionStorage.setItem('userType', 'tourist')
        sessionStorage.setItem('token', res.data.token);
        sessionStorage.setItem('userInfo', JSON.stringify(res.data));
        location.reload();
        // this.GaugeList();
      }
    },
    },
    created() {
        if (!sessionStorage.getItem('token')) {
            this.tourist();
        } 
        this.isPc = sessionStorage.getItem('isPc')
    }
}
</script>

<style lang="scss" scoped>
.index {
    width: 100%;
    height: 100%;

    .banner {
        img {
            height: 300px;
            width: 100%;
        }
    }

    .main {
        display: block;

        // background-color: saddlebrown;

    }
}
</style>